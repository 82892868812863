.main
    lost-utility clearfix
    padding 2em 0
    color white
    min-height 400px
    min-height 40vh
    lost-column 1/2
    +below('l')
        lost-column 1/1


.content
    padding 0 6em
    font-size 1rem
    line-height 1.7
    margin-top 9em
    &.small
        margin-top 3em
    +below('m')
        padding 0 2em
        margin-top 3em

    p
        margin 0 0 1em
    strong
        font-weight 600
