.header
    line-height 1.6
    background #1a1a1a
    position fixed
    z-index 1
    color white
    lost-column 1/2 1 0
    &.invisible
        +above('l')
            display none
    +below('l')
        lost-column 1/1 1 0
        text-align center
