html
    font-size 15px
    +below('s')
        font-size 90%

body
    font-family 'Open Sans Light';
    font-weight 300
    font-size 1rem
    color #333
    line-height 1.6
    //lost-utility edit

a
    color inherit
    text-decoration none

hr
    border none
    border-bottom 1px solid #ddd