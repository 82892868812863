.tag
  list-style: none;
  padding: 0;
  margin: 0;
  margin 1rem 0 3rem
  &--item
    display: inline-block;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    box-shadow 3px 3px 5px rgba(0,0,0,0.1)
    margin 0.75rem 0.5rem 0 0
