transitionProperties = .5s cubic-bezier(.16, .68, .43, .99)

.hamburger
    display none
    position relative
    left 0
    top 0
    padding 0.6rem 0
    font-size 1.5rem
    color white
    text-transform uppercase
    font-weight 500
    box-shadow 0px 2px 7px $dark-grey

    +below('l')
        display block

.nav-offscreen
    .nav-item
        display block
        &.active .nav-link:after
            content ""
            border-radius: 50%;
            width 25px
            height 25px
            position absolute
            z-index 10
            right 0px
            top 12px
        &.active:nth-child(1) .nav-link:after
            background-color $caresuite
        &.active:nth-child(2) .nav-link:after
            background-color $infoterminal
        &.active:nth-child(3) .nav-link:after
            background-color $klow
        &.active:nth-child(4) .nav-link:after
            background-color $beobachter
        &.active:nth-child(5) .nav-link:after
            background-color $offline
        &:hover, &.active
            color white
            background inherit

    .nav-link
        font-weight 500
        margin-top .5em
        font-size 1rem
        line-height 1.5
        position relative
        padding 0
        margin-bottom .5em
        border none

    .nav-sub
        display block
        margin-left 1em
        position static
        .nav-link
            font-weight normal


.offscreen-nav
    background $dark-grey
    width offscreen-nav-width
    height 100%
    color white
    position fixed
    left 0
    top 0
    transform translate3d(-100%, 0, 0)
    transition transform transitionProperties
    z-index: 6000
    overflow-y auto
    will-change transform
    padding: 2.5em 40px

    .nav-item
        margin-left 0
        padding-bottom 1.25em
        margin-bottom 1.25em
        border-bottom 1px solid #262626

    .nav-item--active .nav-link
        font-weight bold

.site-overlay
    background rgba(0,0,0,.5)
    position fixed
    left 0
    top 0
    width 100%
    height 100%
    z-index: 5000
    visibility hidden
    opacity 0
    will-change opacity, visibility
    transition all transitionProperties

.site-wrapper, .nav-push
    transform translate3d(0, 0, 0)
    transition transform transitionProperties
    will-change transform

body.nav-visible
    background #333
    overflow-x hidden

    .header
        opacity 0

    .site-wrapper
        overflow hidden
        background #fff

    .site-wrapper, .nav-push
        transform scale(.9)

    .offscreen-nav
        transform translate3d(0, 0, 0)
        box-shadow 0 0 25px rgba(0,0,0,.2)

    .site-overlay
        visibility visible
        opacity 1
