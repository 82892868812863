.project
  &--items
    position fixed
    right 0
    top 0
    lost-column 1/2 1 0
    height 100%
    color white
    +below('l')
      position: relative
      lost-column 1/1
  &--item
    height 20%
    display block
    lost-align center
    box-shadow 0px -5px 10px rgba(0,0,0,0.25)
    transition transform 0.5s ease
    &:hover
      transform: scale(1.05)

    img
      width 425px
      max-height 90%
      +below('l')
        max-width 80%
        max-height 60%
        width auto
