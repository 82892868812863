.nav-main
    lost-column 1/1
    .nav-items
        +below('l')
            display none

.nav-items
    margin 0
    padding 0

.nav-main .nav-item
    display inline-block
    padding 0
    margin 0
    lost-column 1/5 5 0
    position relative
    &:first-child
        margin-left 0

    +below('m')
        margin 0 1em

    &:hover .nav-sub
        display block

.nav-link
    display block
    color inherit
    text-transform uppercase
    font-weight: 600
    padding 1.9em 0 1.9em 2em
    border-right 1px solid #262626

.nav-main .nav-item:last-child .nav-link
    border 0px

.nav-main .nav-sub
    position absolute
    z-index 1
    padding-left 0
    display none
    left 0
    top 51px
    min-width 250px
    box-shadow 0 2px 6px rgba(0, 0, 0, .1)
    background #fff
    .nav-item
        display block
        margin 0

    .nav-link
        display block
        margin 0
        padding .8em
