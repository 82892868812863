h1
  font-family: utopia-std;
  font-weight: 400;
  font-style: italic;
  font-size 2.5rem
  strong
    font-weight 700
    font-style normal
  a
    text-decoration underline

h2
  margin-top 2rem
  font-family: utopia-std;
  font-weight: 400;