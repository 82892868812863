.caresuite-bg, .caresuite-hover:hover, .caresuite-hover.active
  background-color $caresuite
.caresuite-bg-lighten
  background-color lighten($caresuite, 10%)
.caresuite-bg-darken
  background-color darken($caresuite, 25%)
  &:hover
    background-color darken($caresuite, 33%)

.infoterminal-bg, .infoterminal-hover:hover, .infoterminal-hover.active
  background-color $infoterminal
.infoterminal-bg-lighten
  background-color lighten($infoterminal, 10%)
.infoterminal-bg-darken
  background-color darken($infoterminal, 25%)
  &:hover
    background-color darken($infoterminal, 33%)

.klow-bg, .klow-hover:hover, .klow-hover.active
  background-color $klow
.klow-bg-lighten
  background-color lighten($klow, 15%)
.klow-bg-darken
  background-color lighten($klow, 25%)
  &:hover
    background-color lighten($klow, 50%)

.beobachter-bg, .beobachter-hover:hover, .beobachter-hover.active
  background-color $beobachter
.beobachter-bg-lighten
  background-color lighten($beobachter, 25%)
.beobachter-bg-darken
  background-color darken($beobachter, 15%)
  &:hover
    background-color darken($beobachter, 33%)

.offline-bg, .offline-hover:hover, .offline-hover.active
  background-color $offline
.offline-bg-lighten
  background-color lighten($offline, 15%)
.offline-bg-darken
  background-color darken($offline, 25%)
  &:hover
    background-color darken($offline, 50%)

.dark-grey-bg, .dark-grey-hover:hover, .dark-grey-hover.active
  background-color $dark-grey
.dark-grey-bg-lighten
  background-color lighten($dark-grey, 05%)
.dark-grey-bg-darken
  background-color darken($dark-grey, 25%)
  &:hover
    background-color darken($dark-grey, 50%)