.screenshot
  width 50.05%
  position fixed
  right 0
  top 0
  z-index 10
  display: block
  overflow hidden
  +above('xl')
    height 100%
    cursor zoom-in
  transition width 0.5s ease, z-index 3s ease
  +below('l')
    position: relative
    lost-column 1 / 1
  img
    max-height 100%
    +below('l')
      max-width 100%
  &:hover
    +above('xl')
      width 51%
      .overlay
        opacity 1

  &.collapsed
    +above('xl')
      width 100%
      text-align center
      z-index 10
      cursor zoom-out
      .overlay
        opacity 0

  .overlay
    opacity 0
    position: absolute;
    height: 100%;
    color: white;
    width: 100%;
    background: rgba(0,0,0,0.5);
    transition opacity 0.5s ease
    &-msg
      font-size 2rem
      height 100%
      display block
      margin-top -2rem
      transform translateY(50%)
      text-align center
      i
        padding-right 1rem
